import { WhatsApp } from "@mui/icons-material";
import { Button, Grid, IconButton, MobileStepper, Paper, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

import Desktop from '../images/desktop.png';
import AllinOne from '../images/AllinOne.png';
import Pc from '../images/pc.jpg';
import { Box } from "@mui/system";

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import styled from "styled-components";
import { useStyles } from "./styles";
import { Products } from "./products";
import { useParams } from "react-router-dom";
import { useEffect } from "react";


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
    {
        label:'img1',
        imgPath:Pc,
    },
    {
        label:'img2',
        imgPath:Desktop,
    },
    {
        label:'img3',
        imgPath:AllinOne,
    }
]

const teste = ['1','2','3','4','5'];



const ItemLayout =()=> {

  const {title} = useParams();

  // useEffect(() => {
  //   Products.
  // })

    const styles = useStyles();


    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;
  
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStepChange = (step) => {
      setActiveStep(step);
    };

    return (
      <Box> 
        <Grid container alignItems="center">
            <Grid item xs={12} sx={styles.responsiveGridItem}>
                <Typography variant="h5" fontWeight="500" color="primary" sx={{mb:3}}>
                    Nome do produto
                </Typography>
            </Grid>
            <Grid item xs={12} md={5} sx={styles.responsiveGridItem}>
            <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                    mr:'auto',
                    ml:'auto',
                  display: 'block',
                  maxWidth: 550,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{backgroundColor:'#fff',
                ml:'auto',
                mr:'auto',
                width:'100%',
            }}
            nextButton={
                <IconButton
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                  color="primary"
                >
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </IconButton>
              }
              backButton={
                <IconButton 
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    color="primary">
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                </IconButton>
              }
      /> 
            </Grid>

            <Grid item xs={12} md={7} sx={styles.responsiveGridItem}>
                <Typography variant="h6" color="secondary" sx={{mb:2}}>
                    Subtitle Description
                </Typography>

                <Typography variant="body1">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus doloribus voluptatibus ipsa assumenda quibusdam, qui quia ratione nemo et saepe eveniet obcaecati ut, a, minima deleniti nesciunt eaque consectetur expedita.
                </Typography>

                <Button variant="outlined" size="small" startIcon={<WhatsApp />} sx={{mt:2}} href="https://api.whatsapp.com/send?phone=5511982875877" target="_blank">
                    Mais Informações
                </Button>
            </Grid>

            <Grid item xs={12} sx={styles.topDivider}>

            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={styles.responsiveGridItem}>
            <Grid item xs={12} sx={{mb:2}}>
                <Typography variant="h5" fontWeight="500" color="primary">
                    Outros produtos
                </Typography>
            </Grid>
            {Products.map(({title,image, description }, index)=> (
                
            <Grid item xs={12} md={4} key={index}>
                  <Paper elevation={2} sx={styles.gridProducts}>
                    

                    <Typography variant="h6" color="primary">
                            {title}
                    </Typography>

                    <img src={image} width="90%" />

                    <Typography variant="body1" sx={styles.textStyle}>
                    {description}
                    </Typography>
            
                    </Paper>
            </Grid>
            ))}
        </Grid>
      </Box> 

    );
};

export default ItemLayout;