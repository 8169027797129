export const useStyles =() => ({
    footerTitle:{
        paddingBottom:'10px',
    },
    footerContainer:{
        height:{xs:'600px', md:'230px',},
        marginRight:'auto',
        marginLeft:'auto',
        marginTop:{xs:'20px',md:'60px'},
        justifyContent:'center',
        paddingLeft:'30px',
        paddingRight:'30px',
        textAlign:{xs:'center', md:'left',},
    }
});