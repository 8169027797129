import Desktop from '../images/desktop.png';
import AllinOne from '../images/AllinOne.png';
import Pc from '../images/pc.jpg';
import Notebook from '../images/notebook.jpg';
import MouseTeclado from '../images/mouse-teclado.jpg';
import HeadSet from '../images/headset.png';
import KitUpgrade from '../images/kitupgrade.jpg';
import MotherBoard from '../images/placa-mae.jpg';
import Processadores from '../images/processadores.jpg';
import VideoFonte from '../images/video-e-fonte.jpg';
import PlacaDeVideo from '../images/placa-de-video.jpg';
import Fonte from '../images/fontes.jpg';
import SsdERam from '../images/ssd-e-ram.jpg';
import Impressora from '../images/impressora.jpg';

export const Products = [
    {
        categoryTitle:"COMPUTADORES",
        hasCategory:true,
        category:"Computadores",
        title:"DESKTOPS",
        description:"Ampla linha de modelos de computadores com configurações ideais para atender desde usuários mais exigentes até serviços mais básicos.",
        productDescription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis ullam veritatis distinctio, delectus deleniti, alias qui autem molestias incidunt, doloremque eveniet sapiente similique odio voluptates sequi eius atque quia rem?",
        url:"",
        image:Pc,
        reverse:false,
    },
    {
        categoryTitle:"COMPUTADORES",
        hasCategory:false,
        category:"Computadores",
        title:"NOTEBOOKS",
        description:"Notebooks de diversos tamanhos e configurações, ideais para locomoção e praticidade no seu dia a dia.",
        productDescription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis ullam veritatis distinctio, delectus deleniti, alias qui autem molestias incidunt, doloremque eveniet sapiente similique odio voluptates sequi eius atque quia rem?",
        url:"",
        image:Notebook,
        reverse:true,
    },
    {    
        categoryTitle:"COMPUTADORES",   
        hasCategory:false,
        category:"Computadores",
        title:"All IN ONE",
        description:"Computadores modernos de excelente desempenho que ocupam pouco espaço e permitem um ambiente mais organizado.",
        productDescription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis ullam veritatis distinctio, delectus deleniti, alias qui autem molestias incidunt, doloremque eveniet sapiente similique odio voluptates sequi eius atque quia rem?",
        url:"",
        image:AllinOne,
        reverse:false,
    },
    {
        categoryTitle:"PERIFÉRICOS",
        hasCategory:true,
        category:"Periféricos",
        title:"GABINETES",
        description:"Gabinetes de todos os tamanhos para se adequar ao espaço do ambiente ou para comportar as peças de máquinas de alto desempenho.",
        productDescription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis ullam veritatis distinctio, delectus deleniti, alias qui autem molestias incidunt, doloremque eveniet sapiente similique odio voluptates sequi eius atque quia rem?",
        url:"",
        image:Desktop,
        reverse:false,
    },
    {
        categoryTitle:"Periféricos",
        hasCategory:false,
        category:"Periféricos",
        title:"HEADSETS",
        description:"Headsets confortáveis de ótima performance de aúdio de entrada e saída.",
        productDescription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis ullam veritatis distinctio, delectus deleniti, alias qui autem molestias incidunt, doloremque eveniet sapiente similique odio voluptates sequi eius atque quia rem?",
        url:"",
        image:HeadSet,
        reverse:true,
    },
    {
        categoryTitle:"Periféricos",
        hasCategory:false,
        category:"Periféricos",
        title:"MOUSES E TECLADOS",
        description:"Oferecemos mouses e teclados desde os modelos básicos até os de alta precisão e tempo de resposta.",
        productDescription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis ullam veritatis distinctio, delectus deleniti, alias qui autem molestias incidunt, doloremque eveniet sapiente similique odio voluptates sequi eius atque quia rem?",
        url:"",
        image:MouseTeclado,
        reverse:false,
    }, 
    {
        categoryTitle:"HARDWARE",
        hasCategory:true,
        category:"Hardware",
        title:"PLACAS MÃE",
        description:"Grande variedade de modelos de placas mãe que comportam até mesmo os mais novos processadores do mercado, garantindo umas excelente experiência para o usuário.",
        productDescription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis ullam veritatis distinctio, delectus deleniti, alias qui autem molestias incidunt, doloremque eveniet sapiente similique odio voluptates sequi eius atque quia rem?",
        url:"",
        image:MotherBoard,
        reverse:false,
    },
    {
        categoryTitle:"HARDWARE",
        hasCategory:false,
        category:"Hardware",
        title:"PROCESSADORES",
        description:"Os melhores modelos de processadores Intel e Amd para lidar com multitarefas enquanto trabalha ou se diverte.",
        productDescription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis ullam veritatis distinctio, delectus deleniti, alias qui autem molestias incidunt, doloremque eveniet sapiente similique odio voluptates sequi eius atque quia rem?",
        url:"",
        image:Processadores,
        reverse:true,
    },
    {
        categoryTitle:"HARDWARE",
        hasCategory:false,
        category:"Hardware",
        title:"PLACAS DE VÍDEO",
        description:"Eleve sua experiência com jogos ou programas de maneira fluída com nossas placas de vídeo. ",
        productDescription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis ullam veritatis distinctio, delectus deleniti, alias qui autem molestias incidunt, doloremque eveniet sapiente similique odio voluptates sequi eius atque quia rem?",
        url:"",
        image:PlacaDeVideo,
        reverse:false,
    },
    {
        categoryTitle:"HARDWARE",
        hasCategory:false,
        category:"Hardware",
        title:"FONTES",
        description:"A Infinity conta com modelos de fontes de alimentação nominais, reais ou certificadas 80 Plus para atender aos vários tipos de configurações, de forma eficiente, silenciosa e com preço competitivo.",
        productDescription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis ullam veritatis distinctio, delectus deleniti, alias qui autem molestias incidunt, doloremque eveniet sapiente similique odio voluptates sequi eius atque quia rem?",
        url:"",
        image:Fonte,
        reverse:true,
        },
    {
        categoryTitle:"HARDWARE",
        hasCategory:false,
        category:"Hardware",
        title:"SSD E MEMÓRIA RAM",
        description:"Dispositivos de armazenamento de alta velocidade ou capacidade. Memórias RAM de todas as velocidades de frequência.",
        productDescription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis ullam veritatis distinctio, delectus deleniti, alias qui autem molestias incidunt, doloremque eveniet sapiente similique odio voluptates sequi eius atque quia rem?",
        url:"",
        image:SsdERam,
        reverse:false,
    },
    {
        categoryTitle:"AUTOMAÇÃO",
        hasCategory:true,
        category:"Automação",
        title:"IMPRESSORAS E OUTROS PRODUTOS",
        productDescription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis ullam veritatis distinctio, delectus deleniti, alias qui autem molestias incidunt, doloremque eveniet sapiente similique odio voluptates sequi eius atque quia rem?",
        url:"",
        description:"Torne seu negócio mais eficiente com as soluções de automação comercial.",
        image:Impressora,
        reverse:false,
    },
    
    {
        categoryTitle:"LINHA GREEN",
        hasCategory:true,
        category:"Linha Green",
        title:"KITS PARA UPGRADE",
        description:"Nossa linha custo benefício que entrega peças de ótima performance por um excelente custo benefício.",
        productDescription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis ullam veritatis distinctio, delectus deleniti, alias qui autem molestias incidunt, doloremque eveniet sapiente similique odio voluptates sequi eius atque quia rem?",
        url:"",
        image:KitUpgrade,
        reverse:false,
    },    
];