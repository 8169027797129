import { Button, Grid, Paper, Typography, Box } from "@mui/material";
import React from "react";

import ssd from "../images/ssd.jpg";
import desktop from "../images/desktop.jpg";
import allInOne from "../images/all-in-one.jpg";

import { useStyles } from "./styles";
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';

const Section3 =() => {

    const styles = useStyles();

    return (
        <Grid container maxWidth="lg" sx={styles.section3Container}>


            <Grid item xs={12}>
                <Typography variant="h4" color="primary" textAlign="center" fontWeight={500} sx={styles.titleSpacing}>
                    Produtos
                </Typography>

                <Typography variant="body1" sx={styles.textBottomSpacing}>
                   Conheça alguns de nossos produtos:
                </Typography>
            </Grid>

            
            <Grid item xs={12} md={4}>
                    <Paper elevation={2} sx={styles.gridProducts}>
                        <img src={allInOne} width="90%" />

                        <Typography variant="h6" color="primary">
                            All In One
                        </Typography>

                        <Typography variant="body1" sx={styles.textStyle}>
                        Computadores de excelente performance, ideiais para um espaço organizado.
                        </Typography>
            
                    </Paper>
            </Grid>
            

           <Grid item xs={12} md={4}>
                <Paper elevation={2} sx={styles.gridProducts}>
                    <img src={desktop} width="90%" />

                    <Typography variant="h6" color="primary">
                            Gabinetes
                    </Typography>

                    <Typography variant="body1" sx={styles.textStyle}>
                        Grande variedade de modelos de gabinetes para atender todas as necessidades.
                    </Typography>
            
                </Paper>
            </Grid>

            <Grid item xs={12} md={4} >
                    <Paper elevation={2} sx={styles.gridProducts}>
                    <img src={ssd} width="90%" />

                    <Typography variant="h6" color="primary">
                            SSD
                    </Typography>

                    <Typography variant="body1" sx={styles.textStyle}>
                    Dispositivos de
                    armazenamento de diversas marcas,
                    modelos e tamanhos. Hds,
                    Ssds e M.2.
                    </Typography>
            
                    </Paper>
            </Grid>

                <Button
                color="secondary"
                endIcon={<KeyboardDoubleArrowRightRoundedIcon />}
                sx={{mr:'auto', ml:3, mt:2}}
                href="/produtos"
                >
                    Saiba mais
                </Button>
            

        </Grid>


    );
};

export default Section3;