import produtosMain from '../images/produtosMain2.jpg';

export const useStyles =() => ({
    imageBox:{
        backgroundImage:{xs:`linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),url(${produtosMain})`,
        md:`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)), url(${produtosMain})`},
        backgroundPosition:{xs:'62% 0%',md:'center'}, 
        backgroundRepeat:'no-repeat',
        height:{xs:'560px', md:'720px'},
    },
    mainContainer:{
        marginLeft:'auto',
        marginRight:'auto',
        padding:{xs:'10px',md:'30px'},    
    },
    mainTextGrid:{
        marginTop:{xs:'80px', md:'140px'},
        padding:'20px'
    },
    headerText:{
        maxWidth:{xs:'100%', md:'50%'},
        textAlign:{xs:'center',md:'left'},
    },
    sectionContainer:{
            maxWidth:'100%',
            marginLeft:{xs:'10px',md:'40px'},
            marginRight:{xs:'10px',md:'40px'},
            marginTop:'-70px',
            backdropFilter: 'blur(50px)',
            WebkitBackdropFilter: 'blur(10px)',
            backgroundColor:'rgba(255,255,255, .9)',
            paddingBottom:6,
    },
    section1Container:{
        paddingBottom:'40px',
        paddingRight:{xs:'10px',md:'20px'},
        paddingLeft:{xs:'10px',md:'20px'},
    },
    section3Container:{justifyContent:'center',
    textAlign:'center',
    paddingBottom:'60px',
    },
    iconItemsBlue:{
        backgroundImage:'linear-gradient(180deg, #29a6df , #2e3c90 )',
        width:'40px',
        height:'40px',
        padding:'8px',
        color:'#fff',
        borderRadius:'8px',
        boxShadow:'0px 2px 4px -1px #29a6df',
    },
    iconItemsOrange:{
        backgroundImage:'linear-gradient(180deg, #f68b24 , #f15c2d )',
        width:'40px',
        height:'40px',
        padding:'8px',
        color:'#fff',
        borderRadius:'8px',
        boxShadow:'0px 2px 4px -1px #f68b24',
    },
    textStyle:{
        paddingBottom:'20px',
        textAlign:{xs:'justify',md:'left'},
        //maxWidth:'500px',
    },
    orangeContainer:{
        borderRadius:'16px',
        padding:'20px',
        backgroundImage:'linear-gradient(180deg, #f68b24 , #f15c2d )',
        color:'#fff',
        paddingTop:'40px',
        paddingBottom:'40px',
        boxShadow:'0px 2px 4px -1px #f68b24',
        marginTop:'40px',
        marginBottom:'60px',
    },
    gridProducts:{
        padding:'16px',
    },
    footerTitle:{
        paddingBottom:'10px',
    },
    footerContainer:{
        height:{xs:'600px', md:'260px',},
        marginRight:'auto',
        marginLeft:'auto',
        marginTop:{xs:'20px',md:'60px'},
        justifyContent:'center',
        paddingLeft:'30px',
        paddingRight:'30px',
        textAlign:{xs:'center', md:'left',},
    },
    responsiveTitle:{
        fontSize:{xs:'1.5rem', md:'2.125rem'},
        pb:5,
        pl:{xs:2, sm:4, md:4, xl:10},
        // textAlign:'center',
    },
    responsiveFilterTitle:{
        fontSize:{xs:'1.5rem', md:'1.75rem'},
        ml:-1,
        // textAlign:'center',
    },
    responsiveItemTitle:{
        fontSize:{xs:'1.25rem', md:'1.5rem'},
        pb:{xs:1,md:2,},
    },
    noPadding:{
        p:0,
    },
    hidItem:{
        display:'none',
    },
    responsiveGridPadding:{
        p:{xs:1, sm:2, lg:3,}
    },
    responsiveCategory:{
        visibility:{xs:'visible', lg:'hidden'},
        display:{lg:'none'},
        backgroundColor:'rgba(255,255,255, .9)'
    },
    responsiveList:{
        visibility:{xs:'hidden', md:'visible'},
        display:{xs:'none', lg:'block'},
    }
});