import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Link from '@mui/material/Link';

import LogoFooter from "../pages/images/logo-footer.svg";
import { useStyles } from "./componentsStyles";

const Ifooter =() => {

    const styles = useStyles();

    return (
        <Grid container maxWidth="lg" sx={styles.footerContainer} >
            <Grid item xs={12} lg={3}>
                <img src={LogoFooter} width="160px" height="160px" />
            </Grid>

            <Grid item xs={12} lg={3}>
                
                    <Typography variant="h6" sx={styles.footerTitle}>
                        Menu
                    </Typography>
               
                    <Link href="/" underline="hover"  color="#000000DE">
                        <Typography variant="body2">
                        Home
                        </Typography>
                    </Link>
                    <Link href="/produtos" underline="hover"  variant="body2" color="#000000DE">
                        <Typography variant="body2">
                            Produtos
                        </Typography>
                    </Link>
                    <Link href="/sobre" underline="hover"  variant="body2" color="#000000DE">
                        <Typography variant="body2">
                            Sobre
                        </Typography>
                    </Link>
                    <Link href="https://api.whatsapp.com/send?phone=5511982875877" target="_blank" underline="hover"  variant="body2" color="#000000DE">
                        <Typography variant="body2">
                            Contato
                        </Typography>
                    </Link>
            
            </Grid>
             
            <Grid item xs={12} lg={3}>

                <Typography variant="h6" sx={styles.footerTitle}>
                    Contato
                </Typography>

                <Typography variant="body2">
                    (11) 2180-1797
                </Typography>

                <Typography variant="body2">
                    (11) 98287-5877
                </Typography>

                <Typography variant="body2">
                    financeiro@infinitydist.com.br
                </Typography>
                
            </Grid>

            <Grid item xs={12} lg={3}>

                <Typography variant="h6" sx={styles.footerTitle}>
                    Endereço
                </Typography>

                <Typography variant="body2">
                    CEP 29.161-230
                </Typography>

                <Typography variant="body2">
                    Rodovia Governador Mario Covas, Km 279
                </Typography>

                <Typography variant="body2">
                    Jacuhy, Serra - ES
                </Typography>
                
            </Grid>
            <Grid item xs={12}>
                <Divider />
                <Typography variant="body2" sx={{pt:1, textAlign:'center',}}>
                    Todos os direitos reservados. Copyright ©2022 Infinity
                </Typography>
            </Grid>
        </Grid>

    );
};

export default Ifooter;