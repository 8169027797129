import mainSobre from '../images/mainSobre.jpg';

export const useStyles =() => ({
    mainContainer:{
        marginLeft:'auto',
        marginRight:'auto',
        padding:{xs:'10px',md:'30px'},    
    },
    imageBox:{
        backgroundImage:`url(${mainSobre})`,
        backgroundPosition:{xs:'72% 45%',md:'center'}, 
        backgroundRepeat:'no-repeat',
        height:{xs:'440px',md:'649px'},
        width:{xs:'100%', md:'auto'}
    },
    sectionContainer:{
        maxWidth:'100%',
        marginLeft:{xs:'10px',md:'40px'},
        marginRight:{xs:'10px',md:'40px'},
        marginTop:'-70px',
        backdropFilter: 'blur(50px)',
        WebkitBackdropFilter: 'blur(10px)',
        backgroundColor:'rgba(255,255,255, .8)',
    },
    section1Container:{
        mr:'auto',
        ml:'auto',
        padding:{xs:'0', md:'0 10px 10px 10px',lg:'0 40px 40px 40px'},
    },
    section2Container:{
        pt:{xs:2,md:3},
        pb:{xs:2,md:3},
        pl:{xs:2,md:3},
        backgroundImage:'linear-gradient(180deg, #29a6df , #2e3c90 )',
        borderRadius:'16px',
    },
    gridSpacing:{
        pr:{xs:2,md:3},
        pt:{xs:2,md:3},
        pb:{xs:2,md:3},
    },
    paperSpacing:{
        padding:3,
        backgroundColor:'#fff',
        // mr:2,
        // mb:{xs:2,lg:0},
        height:'100%',
    },
    iconGrid:{
        p:{xs:0,md:3},
    },
    gridPadding:{
        padding:'16px',
    },
    section3Container:{
        textAlign:'center',
        mr:'auto',
        ml:'auto',
        padding:{xs:'20px',
                lg:'40px'
            },
        mt:'40px',
    },
    responsiveTitle:{
        paddingBottom:4,
        fontSize:{xs:'1.5rem', md:'2.125rem'},
    }
});