import React from "react";
import {
    Box, Container, Grid, Paper
    }
from "@mui/material/";
import MenuToolbar from "../../components/MenuToolbar";
import { useStyles } from "./styles";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Ifooter from "../../components/footer";


const Sobre =() => {

    const styles = useStyles();
    
    return (

        <Container maxWidth="100%" disableGutters={true}>
            <Box maxWidth="100%" sx={styles.imageBox}>
                <Grid container maxWidth="xl" sx={styles.mainContainer}>
                    <MenuToolbar />

                </Grid>

            </Box>
                <Paper elevation={3} sx={styles.sectionContainer}>
                    <Box maxWidth="xl" sx={{padding:'16px',marginLeft:'auto', marginRight:'auto',}}>
                        <Section1 />
                        <Section2 />
                        <Section3 />
                    </Box>
                </Paper>
                <Box>
                    <Ifooter />
                </Box>
        </Container>

    );
};

export default Sobre;
