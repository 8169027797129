import { 
    Grid,
    Icon,
    Typography,
} from "@mui/material";
import React from "react";
import LaptopChromebookRoundedIcon from '@mui/icons-material/LaptopChromebookRounded';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { useStyles } from "./styles";



const Section1 =() => {

    const styles = useStyles();

    return (   
        <Grid container spacing={2} sx={styles.section1Container}>
            <Grid item xs={12} sx={{paddingBottom:'16px',}}>
                <Typography variant="h4" color="primary" fontWeight={500} sx={styles.responsiveTitle}>
                    Tecnologias da Infinity
                </Typography>     
            </Grid> 

            <Grid item xs={12} md={6} sx={styles.infoGrids}>
                <LaptopChromebookRoundedIcon 
                sx={styles.iconItemsBlue}/>
                
                    <Typography variant="h6">
                        Computadores
                    </Typography>

                    <Typography variant="body1" sx={styles.textStyle}>
                       De computadores de alta performance até modelos que visam custo benefício para atender as diversas necessidades do mercado.
                    </Typography>
            </Grid>     

            <Grid item xs={12} md={6} >
                <AdfScannerIcon 
                sx={styles.iconItemsOrange}/>
                
                    <Typography variant="h6">
                        Soluções de Automação
                    </Typography>

                    <Typography variant="body1" sx={styles.textStyle}>
                    Torne seu negócio mais eficiente com os nossos produtos para automação de processo comercial.
                    </Typography>
            </Grid>   

            <Grid item xs={12} md={6}>
                <LocalShippingRoundedIcon
                sx={styles.iconItemsBlue}/>
                
                    <Typography variant="h6">
                        Importação
                    </Typography>

                    <Typography variant="body1" sx={styles.textStyle}>
                        Sempre buscamos trazer os produtos mais recentes para inovar e ser um diferencial no mercado.
                    </Typography>
            </Grid>   

            <Grid item xs={12} md={6} >
                <PrecisionManufacturingIcon
                sx={styles.iconItemsOrange}/>
                
                    <Typography variant="h6">
                        Fabricação 
                    </Typography>

                    <Typography variant="body1" sx={styles.textStyle}>
                        Estamos expandindo cada vez mais nossa linha de produção com Computadores, Notebooks, All in Ones, Gabinetes e Fontes.
                    </Typography>
            </Grid>   
        </Grid>

    );
};

export default Section1;