import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";

import workDeskMini from "../images/workDeskMini.jpg";

const Section2 =() => {

    const styles = useStyles();

    return(
        <Grid container sx={styles.orangeContainer}>

            <Grid item xs={12} md={6} sx={{p:2}}>
                <Typography variant="h5" sx={styles.textStyle} fontWeight={500}>
                    Sobre nós
                </Typography>

                <Typography variant="body1">
                A Infinity se tornou em curto espaço de tempo a maior importadora de produtos de alta tecnologia e informática do Espirito Santo. Fabricamos hoje Computadores, Notebooks,All in Ones, Gabinetes e fontes de alta qualidade, com todos os benefícios ISO 9000 e PPB. Trabalhamos com diversas linhas de produtos para atender de forma ampla as necessidades do mercado.
                </Typography>

            </Grid>

            <Grid item xs={12} md={6} sx={styles.gridImage}>
                <img src={workDeskMini} style={{
                        borderRadius:'16px',
                        boxShadow:'0px 5px 5px -1px rgba(0,0,0,.4)',
                        marginLeft:'auto',
                        marginRight:'auto', 
                        width:'100%'}}/>
            </Grid>
            
        </Grid>

    );
};

export default Section2;