import { Grid, Typography,Box, Button, List, ListItem, ListItemText, ListItemIcon, Checkbox, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Desktop from '../images/desktop.png';
import AllinOne from '../images/AllinOne.png';
import React from "react";
import { Products } from "./products";
import { useStyles } from "./styles";
import { WhatsApp } from "@mui/icons-material";
import { useState } from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const SectionProducts =() => {

    const categoriesList = ['Computadores', 'Periféricos', 'Hardware', 'Automação', 'Linha Green'];

    const [selectedCategories, setSelectedCategories] = useState([ ]);
    console.log('aaray', selectedCategories)

    const selectCategory = (event) => {

        const findCategory = selectedCategories.find(category => category === event.target.value);
        console.log('achou',findCategory)

        const checkedCategories = [...selectedCategories]
        

        if (findCategory === undefined){
            checkedCategories.push(event.target.value)
        }
        else{
            const itemIndex = checkedCategories.indexOf(findCategory)
            checkedCategories.splice(itemIndex, 1);
        };
        setSelectedCategories(checkedCategories);
        console.log(selectedCategories)
    }

    const styles = useStyles();

    const MobileCategory =()=> {


        return(
        <Accordion sx={styles.responsiveCategory} elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="mobile-catecory"
            >
              <ListItem sx={{p:0,backgroundColor:'rgba(255,255,255, .9)'}}>
                            <ListItemIcon sx={{pl:1}}>
                                <FilterAltIcon color="primary" />
                            </ListItemIcon>
                            <Typography variant="h5" fontWeight={500} color="primary" sx={{fontSize:{xs:'1.25rem', md:'1.5rem'},
            pb:{xs:1,md:2,},}}>
                                Categorias
                            </Typography>
                        </ListItem>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                        {categoriesList.map((categories, index) => ( 
                            <ListItem key={index} sx={{p:0}}>
                                <ListItemIcon>
                                    <Checkbox value={categories} checked={selectedCategories.includes(categories)} onChange={selectCategory} />
                                </ListItemIcon>
                                <ListItemText primary={categories} />
                            </ListItem>
                        ))}
              </List>
            </AccordionDetails>
          </Accordion>
        );
    }

    return (
        <Grid container>
            <Grid item xs={12} lg={2} sx={{pt:{sm:2, lg:3,}}}>
                
                <List sx={styles.responsiveList}>
                    <ListItem sx={styles.noPadding}>
                        <ListItemIcon sx={{pl:1}}>
                            <FilterAltIcon color="primary" />
                        </ListItemIcon>
                        <Typography variant="h5" fontWeight={500} color="primary" sx={styles.responsiveFilterTitle}>
                            Categorias
                        </Typography>
                    </ListItem>
                    {categoriesList.map((categories, index) => ( 
                        <ListItem key={index} sx={styles.noPadding}>
                            <ListItemIcon>
                                <Checkbox value={categories} checked={selectedCategories.includes(categories)} onChange={selectCategory} />
                            </ListItemIcon>
                            <ListItemText primary={categories} />
                        </ListItem>
                    ))}
                </List>
                <MobileCategory />
            </Grid>
        <Grid item xs={12} lg={10}>
            {Products.map(({categoryTitle,hasCategory, category, title,description, image, reverse}, index)=> (                 
        <Grid container maxWidth="xl" alignItems="center" sx={selectedCategories.includes(category) || selectedCategories.length === 0 ? styles.responsiveGridPadding : styles.hidItem}
        key={index}
        direction={{md:reverse ? "row-reverse" : "row" }}
        >
            {hasCategory ? (
            <Grid item xs={12} sx={selectedCategories.indexOf(category) !== 0 && categoryTitle !== "COMPUTADORES" ? {borderTop:'1px solid #ddd', pt:6} : {pt:1}}>
                <Typography variant="h4" fontWeight={500} color="primary" sx={styles.responsiveTitle}>
                  {categoryTitle}
                </Typography>

            </Grid>
        ) : null}
            <Grid item xs={12} md={6} sx={{pl:{xs:2, sm:4, md:4, xl:10}, pr:{xs:2, sm:4, md:4, xl:10,}}}>
                <img src={image} width="100%" />

            </Grid>

            <Grid item xs={12} md={6} sx={{pl:{xs:2, sm:4, md:4, xl:10}, pr:{xs:2, sm:4, md:4, xl:10},}}>
                <Typography variant="h5" fontWeight={700} sx={styles.responsiveItemTitle} color="secondary">
                    {title}
                </Typography>

                <Typography variant="body2">
                    {description}
                </Typography>

                <Button variant="outlined" size="small" sx={{mt:2}} href="https://api.whatsapp.com/send?phone=5511982875877" target="_blank">
                    Saiba mais
                </Button>
                
            </Grid>
            {/* "produtos/{title}" */}
        </Grid>
        ))}  
        </Grid>
    </Grid>
    );
};

export default SectionProducts;