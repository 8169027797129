import React from "react";
import Box from "@mui/material/Box";
import { 
    Container,
    Typography,
    Grid,
    Paper,  
 } from "@mui/material";


import MenuToolbar from "../../components/MenuToolbar";
import Ifooter from "../../components/footer";

import { useStyles } from "./styles";
import WhatsAppButton from "../../components/whatsAppButton";
import SectionProducts from "./sectionProducts";



const Produtos =() => {

    const styles = useStyles();

    return (

            <Container maxWidth="100%" disableGutters={true}>  
                <Box maxWidth="100%" sx={styles.imageBox}>
                    <Grid container maxWidth="xl" sx={styles.mainContainer}>
                        <Grid item xs={12}>
                            <MenuToolbar />
                        </Grid>
                        <Grid item xs={10} sx={styles.mainTextGrid}>
                            <Typography variant="h4" color="white" sx={styles.headerText} fontWeight="500">
                            Grande variedade de produtos de qualidade para atender as necessidades do mercado
                        </Typography>
                        </Grid>
                    </Grid>  
                </Box>
                <Paper elevation={3} sx={styles.sectionContainer}>
                    <Box maxWidth="xl" sx={{padding:'16px',marginLeft:'auto', marginRight:'auto',}}>   
                                <SectionProducts />                                               
                    </Box>
                </Paper>
                <Box>
                    <Ifooter />
                </Box>
            </Container>

    );
};

export default Produtos;