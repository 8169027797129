import { createTheme } from "@mui/material";

export const InfinityTheme = createTheme ({
    palette: {
        background:{
            default:'#ECEFF1',
        },
        primary:{
            light: '#29a6df',
            main: '#2f77b8',
            dark: '#2e3c90',
            contrastText: '#fff',
        },
        secondary:{
            light: '#f3722a',
            main: '#f3722a',
            dark: '#f15c2d',
            contrastText: '#121212',
        },
        success:{
            light: '#2EA359',
            main: '#0C963F',
            dark: '#064F21',
            contrastText: '#fff',
        },
        warning:{
            light: '#FFD152',
            main: '#FFC72A',
            dark: '#B38B1D',
            contrastText: '#fff',
        }, 
    },
    shape:{
        borderRadius:16,
    }
});

