import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import InfinityTheme from './theme/InfinityTheme';
import { ThemeProvider } from 'styled-components';

import { Routes, Route, BrowserRouter,Router, Switch } from 'react-router-dom';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <App />
  </React.StrictMode>
);

