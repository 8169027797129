import { Grid,
        Typography,
} from "@mui/material";
import React from "react";
import { useStyles } from "./styles";

import TelegramIcon from '@mui/icons-material/Telegram';
import AndroidIcon from '@mui/icons-material/Android';
import GitHubIcon from '@mui/icons-material/GitHub';
import AppleIcon from '@mui/icons-material/Apple';
import CasasBahia from '../images/casasBahiaLogo.svg';
import Kabum from '../images/kabumLogo.svg';
import Americanas from '../images/lojasAmericanasLogo.svg';
import Magalu from '../images/magaluLogo.svg';

const Section3 =() => {

    const styles = useStyles();

    return(

        <Grid container maxWidth="lg" alignItems="center" sx={styles.section3Container}>
            <Grid item xs={12}>
                <Typography variant="h4" fontWeight={500} textAlign="center" sx={styles.responsiveTitle}>
                    Alguns de nossos parceiros
                </Typography>

            </Grid>

                <Grid item xs={12} md={3} sx={styles.iconGrid}>
                    <img src={Americanas} width="80%"  style={{filter:'saturate(0)'}} />
                </Grid>

                <Grid item xs={12} md={3} sx={styles.iconGrid}>
                <img src={Kabum} width="80%" style={{filter:'saturate(0)'}} />
                </Grid>
                    
                <Grid item xs={12} md={3} sx={styles.iconGrid}>
                <img src={Magalu} width="80%" style={{filter:'saturate(0)'}}/>
                </Grid>

                <Grid item xs={12} md={3} sx={styles.iconGrid}>
                <img src={CasasBahia} width="80%" style={{filter:'saturate(0)'}}/>
                </Grid>
            
        </Grid>

    );
};

export default Section3;