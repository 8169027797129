import { 
    AppBar, 
    Paper,
    Toolbar,
    Typography,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    IconButton,
    Drawer,
} from "@mui/material";

import LogoSvg from "../pages/images/logo-svg.svg";
import OnlyLogo from "../pages/images/symbol.svg";
import React from "react";
import { BrowserRouter as Router,useLocation } from "react-router-dom";
import { useState } from "react";
import { MenuRounded } from "@mui/icons-material";
import { breakpoints } from "@mui/system";
import { useEffect } from "react";
import InfinityTheme from "../theme/InfinityTheme";





function MenuButton () {

    const location = useLocation();
    const selectedHome = location.pathname === '/';
    const selectedProdutos = location.pathname === '/produtos';
    const selectedSobre = location.pathname === '/sobre';
    const selectedContato = location.pathname === '/contato';

    const [open, setOpen] = React.useState(false);

       
    
      
    const openMenu = () => {

    setOpen(true);
    };

    const closeMenu = () => {

    setOpen(false);
    };

    const HiddenMenu =() => (

        <Box
              sx={{ width:'250px', }}
              role="presentation"
              onKeyDown={closeMenu}
            >
        <List sx={{
            '& .MuiListItemButton-root':{
            '&.Mui-selected':{
                color:'primary.main',
                backgroundColor:'transparent',
                '&: hover':{
                    backgroundColor:'transparent',
                },
            },
            '&: hover':{
                color:'primary.main',
                backgroundColor:'transparent',
            }},}}>
            <ListItemButton selected={selectedHome} href="/">
                <ListItemText primary="Home" />        
            </ListItemButton>
    
            <ListItemButton selected={selectedProdutos} href="/produtos">
                <ListItemText primary="Produtos" />        
            </ListItemButton>
    
            <ListItemButton selected={selectedSobre} href="sobre">
                <ListItemText primary="Sobre" />        
            </ListItemButton>
    
            <ListItemButton selected={selectedContato}  href="https://api.whatsapp.com/send?phone=5511982875877" target="_blank">
                <ListItemText primary="Contato" />        
            </ListItemButton>
        </List>
    </Box>
    );

    return (

        <div>
       
            <IconButton
            sx={{visibility:{xs:'visible', lg:'hidden'},display:{lg:'none',}}} 
            edge="end"
            onClick={openMenu}
            >
                <MenuRounded  />
            </IconButton>

            <Drawer
                anchor="right"
                open={open}
                onClose={closeMenu}
                onClick={closeMenu}
            >
                <HiddenMenu />
            </Drawer>
        </div>
    );
};



const MenuToolbar =() => {

    const location = useLocation();
    const selectedHome = location.pathname === '/';
    const selectedProdutos = location.pathname === '/produtos';
    const selectedSobre = location.pathname === '/sobre';
    const selectedContato = location.pathname === '/contato';

    return (
        
            <Paper elevation={3} sx={{height:'60px', width:'100%', backdropFilter: 'blur(10px)',
            WebkitBackdropFilter:'blur(10px)', backgroundColor:'rgba(255,255,255, .8)',border: '1px solid rgba(255, 255, 255, 0.3)'}}>
                <ListItem sx={{height:'100%', pr:'16px'}} secondaryAction={<MenuButton />}>

                <Box
                    component="img"
                    sx={{
                        content: {
                            xs: `url(${OnlyLogo})`, 
                            md: `url(${LogoSvg})`,  
                        },
                        width: {
                            xs:'50px',
                            md:'225px',
                        },
                        height:{
                            xs:'40px',
                            md:'40px',
                        },
                    }}
                    alt="Logo"
                />
                
                        <List sx={{
                            visibility:{xs:'hidden', md:'visible'},
                            display:{xs:'none', lg:'flex'},
                            marginLeft:'auto',
                            '& .MuiListItemButton-root':{
                            '&.Mui-selected':{
                                color:'primary.main',
                                backgroundColor:'transparent',
                                '&: hover':{
                                    backgroundColor:'transparent',
                                },
                            },
                            '&: hover':{
                                color:'primary.main',
                                backgroundColor:'transparent',
                            }},}}>
                            <ListItemButton selected={selectedHome} href="/">
                                <ListItemText primary="Home" />        
                            </ListItemButton>

                            <ListItemButton selected={selectedProdutos} href="/produtos">
                                <ListItemText primary="Produtos" />        
                            </ListItemButton>

                            <ListItemButton selected={selectedSobre} href="/sobre">
                                <ListItemText primary="Sobre" />        
                            </ListItemButton>

                            <ListItemButton selected={selectedContato} href="https://api.whatsapp.com/send?phone=5511982875877" target="_blank">
                                <ListItemText primary="Contato" />        
                            </ListItemButton>
                        </List>
                       
                </ListItem>
                

            </Paper>
            
        
    );
};

export default MenuToolbar;