import Contato from '../images/contato.jpg';

export const useStyles =() => ({
    mainContainer:{
        pl:{xs:'10px', md:'40px'},
        pr:{xs:'10px', md:'40px'},
    },
    menuContainer:{
        marginLeft:'auto',
        marginRight:'auto',
        padding:{xs:'16px',md:'30px'},    
    },
    boxImage:{
        backgroundImage:`url(${Contato})`,
        backgroundRepeat:'no-repeat',
        backgroundPosition:'center',
        height:'630px',
        width:'100%',
        borderRadius:'16px',
        marginTop:'-70px',
    },
    gridSpacing:{
        padding:2,
        backgroundColor:'#fff',
        mr:1,
        mb:{xs:2,md:0},
    },
    iconGrid:{
        height:'160px',
        paddingTop:'20px',
    },
    gridPadding:{
        padding:'16px',
    },
    section3Container:{
        textAlign:'center',
        mr:'auto',
        ml:'auto',
        padding:{xs:'20px',
                lg:'40px'
            },
    },
    mainGrid:{
        mr:'auto',
        ml:'auto',
        pt:'80px',
        pl:{xs:'16px',md:'30px'},
        pr:{xs:'16px',md:'30px'},
    },
    contactBox:{
        width:'100%',
        backgroundImage:'linear-gradient(180deg, #29a6df , #2e3c90 )',
        pt:'12px',
        pb:'12px',
        pl:1,
        pr:1,
        borderRadius:'16px',
        mt:-4,
        boxShadow:'0px 2px 4px -1px #29a6df',
        mb:2,
    },
    buttonStyle:{
        mt:1,
        backgroundImage:'linear-gradient(180deg, #29a6df , #2e3c90 )',
        boxShadow:'0px 2px 4px -1px #29a6df',
        pt:1,
        pb:1,
        '&:hover':{
            boxShadow:'0px 3px 10px -1px #29a6df',
            backgroundImage:'linear-gradient(180deg, #29a6dfDE , #2e3c90 )',
            backgroundColor:'transparent',
        }
    },
    responsiveTitle:{
        fontSize:{xs:'1.5rem', md:'2.125rem'},
    },
});