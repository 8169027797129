import { Grid,
    Typography,
    Paper,
} from "@mui/material";
import React from "react";
import { useStyles } from "./styles";
import montagem from "../images/montagemM.jpg";
import gabinetes from "../images/gabinetes.jpg";
import caixas from "../images/caixas.jpg";
import VariedadeGabinetes from "../images/variedade-gabinetes.jpg";

const Section2 =() => {

    const styles = useStyles();

    return(
        <Grid container sx={styles.section2Container}>
            <Grid item xs={12}>
                <Typography variant="h4" color="white" textAlign="center" sx={styles.responsiveTitle}>
                    Sobre nós
                </Typography>

            </Grid>

            
            <Grid item xs={12} md={6} lg={4} sx={styles.gridSpacing}>
                <Paper sx={styles.paperSpacing}> 
                    <img src={montagem} width="100%" style={{borderRadius:'16px', marginTop:'-40px',boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}} />
            
                <Typography variant="h6" fontWeight={500}>
                    Fabricação
                </Typography>

                    <Typography variant="body2">
                    Temos ainda uma linha de montagem completa de
                    gabinetes e fontes, para oferecer os melhores produtos
                    com todos os benefícios a nossos parceiros.
                    </Typography>
                </Paper>
            </Grid>
            
            <Grid item xs={12} md={6} lg={4} sx={styles.gridSpacing}>
                <Paper sx={styles.paperSpacing}> 
                    <img src={caixas} width="100%" style={{borderRadius:'16px', marginTop:'-40px', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}} />
            
                    <Typography variant="h6" fontWeight={500}>
                    Importação
                </Typography>

                <Typography variant="body2">
                Com uma vasta experiência em importação temos os
                melhores produtos e mais vasto estoque a oferecer
                aos nossos parceiros, e sempre com o melhor preço
                conquistado com um trabalho eficiente e com todos
                os benefícios.
                </Typography>
                
                </Paper>
            </Grid>

            <Grid item xs={12} md={6} lg={4} sx={styles.gridSpacing}>
            <Paper sx={styles.paperSpacing}> 
                    <img src={VariedadeGabinetes} width="100%" style={{borderRadius:'16px', marginTop:'-40px', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}} />
                    <Typography variant="h6"fontWeight={500}>
                    Variedade
                </Typography>

                <Typography variant="body2">
                    Possuímos uma grande variedade de produtos em cada um dos setores, o que nos possibilita atender as necessidades de diversos tipos de clientes, desde computares para entreterimento até escritórios.
                </Typography>
                
            </Paper>    
            </Grid>

        </Grid>

    );
};

export default Section2;