import React from "react";
import { ThemeProvider } from "@emotion/react";
import { InfinityTheme } from "./theme/InfinityTheme";

import { Routes, Route, BrowserRouter,Router, Switch } from 'react-router-dom';

import Home from './pages/home/index';
import Sobre from "./pages/sobre/";
import Produtos from "./pages/produtos/index";
import Contato from "./pages/contato/contato";
import { CssBaseline } from "@mui/material";
import Produto from "./pages/produto";

function App() {
  return (
    <ThemeProvider theme={InfinityTheme}>
      <CssBaseline />
      <BrowserRouter>
          <Routes>         
            <Route exact path="/" element={<Home />} />
            <Route exact path="/sobre" element={<Sobre />} />
            <Route exact path="/produtos" element={<Produtos />} />
              {/* <Route exact path="/produtos/:title" element={<Produto />} /> */}
            <Route exact path="/contato" element={<Contato />} />
          </Routes>
      </BrowserRouter>  
    </ThemeProvider>     

  );
};

export default App;
