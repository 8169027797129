import { Grid, Icon, Typography } from "@mui/material";
import React from "react";
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { useStyles } from "./styles";

const Section1 =() => {

    const styles = useStyles();
    return(
        <Grid container maxWidth="lg" sx={styles.section1Container}>
            <Grid item xs={12} sx={styles.gridPadding}>
                <Typography variant="h4" fontWeight={500} color="primary" textAlign="center" sx={styles.responsiveTitle}>
                    A INFINITY
                </Typography>

                <Typography variant="body2"sx={{marginTop:'20px',}}>
                A Infinity se tornou em curto espaço de tempo a maior
                importadora de produtos de alta tecnologia e
                informática do Espirito Santo. Fabricamos hoje Computadores, Notebooks,All in Ones, Gabinetes e Fontes da mais alta
                qualidade, e com todos os benefícios ISO 9000 e PPB. Trabalhamos com diversas linhas de produtos para atender de forma ampla as necessidades do mercado.
                
                </Typography>
            </Grid>

            <Grid item xs={12} md={6} sx={styles.gridPadding}>
                
                   <RocketLaunchRoundedIcon
                   sx={{fontSize:'52px',
                   ml:'4px',
                   color:'#fff',
                   background:'linear-gradient(180deg, #29a6df , #2e3c90 )',
                   borderRadius:'16px',p:1}} />

                    <Typography variant="h6" fontWeight={500}>
                        Missão
                    </Typography>
                <Typography variant="body2">
                Ser referência na fabricação de computadores e distribuição de produtos eletrônicos de excelente qualidade que possuam ótimo desempenho.
                </Typography>
            </Grid>

            <Grid item xs={12} md={6} sx={styles.gridPadding}>

                <StarRoundedIcon sx={{fontSize:'52px', ml:'4px', color:'white', backgroundImage:'linear-gradient(180deg, #29a6df , #2e3c90 )', borderRadius:'16px',p:1}} />     
                    
                    <Typography variant="h6" fontWeight={500}>
                        Visão
                    </Typography>

            <Typography variant="body2">
                A Infinity almeja expandir a variedade de produtos em sua linha de produção em poucos anos para oferecer ao mercado tecnologias de alta performance por valores atrativos.
                </Typography>
                
            </Grid>

        </Grid>

    );
};

export default Section1;