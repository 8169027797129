import React from "react";
import Box from "@mui/material/Box";
import { 
    Container,
    Typography,
    Grid,
    Paper,   
 } from "@mui/material";

import headImage from '../images/headImage.jpg';

import MenuToolbar from "../../components/MenuToolbar";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Ifooter from "../../components/footer";

import { useStyles } from "./styles";
import WhatsAppButton from "../../components/whatsAppButton";


const Home =() => {

    const styles = useStyles();

    return (

            <Container maxWidth="100%" disableGutters={true}>
               
                <Box maxWidth="100%" sx={styles.imageBox}>
                    <Grid container maxWidth="xl" sx={styles.mainContainer}>
                    <Grid item xs={12}>
                        <MenuToolbar />
                    </Grid>
                    <Grid item xs={12} sx={styles.mainTextGrid}>
                        <Typography variant="h4" color="white" sx={styles.headerText} fontWeight="500">
                        Líder em fabricação e importação
                        de produtos de Alta Tecnologia
                        e Informática.
                    </Typography>
                    </Grid>
                    
                    </Grid>
                   
                </Box>
                <Paper elevation={3} sx={styles.sectionContainer}>
                    <Box maxWidth="xl" sx={{padding:'16px',marginLeft:'auto', marginRight:'auto',}}>
                        <Section1 />
                        <Section2 />
                        <Section3 />
                        
                    </Box>
                </Paper>
                <Box>
                    <Ifooter />
                </Box>
            </Container>

    );
};

export default Home;
