import { AccountCircle } from "@mui/icons-material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SendIcon from '@mui/icons-material/Send';
import { FormControl, Typography, Box, TextField, InputAdornment, Button } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";

const ContactForm =() => {

  const styles = useStyles();

  


  const [formData, setFormData] = React.useState({
    nome:"",
    tel:"",
    email:"",
    mensagem:"",
  });
  console.log(formData)


    const handleInputName =(event) => {
      setFormData({...formData, nome:event.target.value})
    };

    const handleInputTel =(event) => {
      setFormData({...formData, tel:event.target.value})
    };

    const handleInputEmail =(event) => {
      setFormData({...formData, email:event.target.value})
    };

    const handleInputMessage = (event) => {
      setFormData({...formData, mensagem:event.target.value})
    };

    const sendMessage =(event) => {
      
    };


    return(

        <Box
         width="100%"
         sx={{p:3,'& .MuiTextField-root': { mr: 1, mb:2,}}}
         >
          <form onSubmit={sendMessage}>
            <Box sx={styles.contactBox}>
                <Typography variant="h4" color="white" fontWeight={400} sx={styles.responsiveTitle}>
                    Contato
                </Typography>
            </Box>
            <TextField
             fullWidth
             type="text"
             value={formData.nome}
             required
             variant="standard"
             label="Nome completo"
             InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonRoundedIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleInputName}
            />

            <TextField
             fullWidth
             type="text"
             value={formData.tel}
             required
             variant="standard"
             label="Tel/WhatsApp"
             InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalPhoneIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleInputTel}
            />

            <TextField
             fullWidth
             type="email"
             value={formData.email}
             required
             variant="standard"
             label="E-mail"
             InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailRoundedIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleInputEmail}
            />

            <TextField
             fullWidth
             type="text"
             value={formData.mensagem}
             required
             variant="standard"
             label="Digite sua mensagem"
             multiline
             //defaultValue="Digite sua mensagem"
             rows={4}
             onChange={handleInputMessage}
            />

            <Button variant="contained" size="medium" endIcon={<SendIcon />}
            sx={styles.buttonStyle}
            type="submit"
            >
                Enviar mensagem
            </Button>
            </form>
        </Box>
    );
};

export default ContactForm;