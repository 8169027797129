import headImage from '../images/headImage.jpg';
import workDeskMini from '../images/workDeskMini.jpg';

export const useStyles =() => ({
    imageBox:{
        backgroundImage:{xs:`linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),url(${headImage})`,
                        md:`url(${headImage})`},
        backgroundPosition:'center', 
        backgroundRepeat:'no-repeat',
        height:{xs:'560px',md:'649px'},
    },
    mainContainer:{
        marginLeft:'auto',
        marginRight:'auto',
        padding:{xs:'10px',md:'30px'},    
    },
    headerText:{
        maxWidth:{xs:'100%', md:'50%'},
        textAlign:{xs:'center',md:'left'},
    },
    mainTextGrid:{
        marginTop:{xs:'80px', md:'140px'},
        padding:'20px'
    },
    sectionContainer:{
            maxWidth:'100%',
            marginLeft:{xs:'10px',md:'40px'},
            marginRight:{xs:'10px',md:'40px'},
            marginTop:'-70px',
            backdropFilter: 'blur(50px)',
            WebkitBackdropFilter: 'blur(10px)',
            backgroundColor:'rgba(255,255,255, .8)',
    },
    section1Container:{
        paddingBottom:'40px',
        paddingRight:{xs:'10px',md:'20px'},
        paddingLeft:{xs:'10px',md:'20px'},
    },
    infoGrids:{
        p:{xs:1,lg:4},
    },
    section3Container:{justifyContent:'center',
    textAlign:'center',
    paddingBottom:'60px',
    ml:'auto',
    mr:'auto',
    },
    iconItemsBlue:{
        backgroundImage:'linear-gradient(180deg, #29a6df , #2e3c90 )',
        width:'40px',
        height:'40px',
        padding:'8px',
        color:'#fff',
        borderRadius:'8px',
        boxShadow:'0px 2px 4px -1px #29a6df',
    },
    iconItemsOrange:{
        backgroundImage:'linear-gradient(180deg, #f68b24 , #f15c2d )',
        width:'40px',
        height:'40px',
        padding:'8px',
        color:'#fff',
        borderRadius:'8px',
        boxShadow:'0px 2px 4px -1px #f68b24',
    },
    textStyle:{
        paddingBottom:'20px',
        textAlign:{xs:'justify',md:'left'},
        //maxWidth:'500px',
    },
    orangeContainer:{
        borderRadius:'16px',
        padding:'20px',
        backgroundImage:'linear-gradient(180deg, #f68b24 , #f15c2d )',
        color:'#fff',
        paddingTop:'40px',
        paddingBottom:'40px',
        boxShadow:'0px 2px 4px -1px #f68b24',
        marginTop:'40px',
        marginBottom:'60px',
        alignItems:'center',
    },
    gridProducts:{
        padding:'16px',
        m:{xs:1, lg:2,},
        height:'92%',
    },
    footerTitle:{
        paddingBottom:'10px',
    },
    footerContainer:{
        height:{xs:'600px', md:'260px',},
        marginRight:'auto',
        marginLeft:'auto',
        marginTop:{xs:'20px',md:'60px'},
        justifyContent:'center',
        paddingLeft:'30px',
        paddingRight:'30px',
        textAlign:{xs:'center', md:'left',},
    },
    titleSpacing:{
        pb:2,
        fontSize:{xs:'1.5rem', md:'2.125rem'},
    },
    textBottomSpacing:{
        pb:2,
    },
    gridImage:{
        marginTop:{xs:'20px', md:'-70px'},
        p:{xs:0,md:1,xl:2},
    },
    responsiveTitle:{
        fontSize:{xs:'1.5rem', md:'2.125rem'},
    },
});