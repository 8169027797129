import React from "react";
import {
    Box, Container, Grid, Paper
    }
from "@mui/material/";
import MenuToolbar from "../../components/MenuToolbar";
import { useStyles } from "./styles";
import Ifooter from "../../components/footer";
import ContactForm from "./formulario";


const Contato =() => {

    const styles = useStyles();
    return (

        <Container maxWidth="100%" disableGutters={true} sx={styles.mainContainer}>
                <Box maxWidth="xl" sx={styles.menuContainer}>
                    <MenuToolbar />
                </Box>
                
                <Box sx={styles.boxImage}>
                    <Grid container maxWidth="xl" direction="row-reverse" sx={styles.mainGrid}>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={8} sx={{width:'100%', pb:2, }}>
                                <ContactForm />
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Ifooter />
                </Box>
        </Container>

    );
};

export default Contato;
